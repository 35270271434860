
import React from 'react'

export const Logo = ({ color = '#000000' }) => (
  <svg width="207" height="22" viewBox="0 0 207 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.0535 13.0613C61.9364 13.5843 61.7612 14.0719 61.534 14.5286C61.0347 15.5356 60.3448 16.3805 59.4668 17.0644C58.5852 17.7472 57.5782 18.2631 56.4387 18.6063C55.3004 18.9518 54.0899 19.1281 52.8143 19.1281H52.2569C50.9801 19.1281 49.772 18.953 48.6313 18.6063C47.4918 18.2631 46.4812 17.7472 45.6044 17.0644C44.7228 16.3817 44.0353 15.5356 43.5324 14.5286C43.0331 13.5181 42.7846 12.3431 42.7846 10.9965C42.7846 9.64985 43.0331 8.47601 43.5324 7.4702C44.0365 6.45966 44.7228 5.61715 45.6044 4.93438C46.4812 4.25161 47.4918 3.73806 48.6313 3.39017C49.7708 3.04346 50.9801 2.8707 52.2569 2.8707H52.8143C54.0899 2.8707 55.3004 3.04346 56.4387 3.39017C57.5782 3.73806 58.584 4.25161 59.4668 4.93438C60.2348 5.53195 60.8595 6.25377 61.3388 7.10101L64.148 5.99699C63.5042 4.76044 62.6499 3.70611 61.5778 2.84111C60.4182 1.90394 59.068 1.19395 57.5203 0.718266C55.9749 0.236661 54.3123 0 52.5338 0C50.7553 0 49.0963 0.236661 47.5474 0.718266C46.002 1.19395 44.6447 1.90394 43.478 2.84111C42.3112 3.77711 41.3989 4.92846 40.7434 6.294C40.0831 7.65953 39.7553 9.22859 39.7553 10.9988C39.7553 12.769 40.0831 14.3428 40.7434 15.7048C41.3977 17.0704 42.3112 18.2205 43.478 19.1577C44.6447 20.0984 46.002 20.8049 47.5474 21.2841C49.0963 21.7598 50.7577 22.0024 52.5338 22.0024C54.31 22.0024 55.9749 21.761 57.5203 21.2841C59.068 20.8049 60.4194 20.0984 61.5778 19.1577C62.7351 18.2217 63.6415 17.0704 64.3006 15.7048C64.6899 14.8966 64.9632 14.0162 65.1194 13.0637H62.0523L62.0535 13.0613Z" fill={color}/>
    <path d="M88.4128 0.304108V9.47235H72.6902V0.304108H69.7178V21.6947H72.6902V12.3442H88.4128V21.6947H91.3864V0.304108H88.4128Z" fill={color}/>
    <path d="M125.539 0.304108L119.835 18.8429L114.26 0.304108H110.234L104.647 18.7921L98.9525 0.304108H95.8179L102.481 21.6947H106.648L112.191 3.51915L117.764 21.6947H121.931L128.594 0.304108H125.539Z" fill={color}/>
    <path d="M150.298 0.304108L142.784 9.64748L135.269 0.304108H131.406L141.266 12.433V21.6947H144.239V12.433L154.102 0.304108H150.298Z" fill={color}/>
    <path d="M158.961 0.304108V21.6947H177.907V18.8205H161.934V0.304108H158.961Z" fill={color}/>
    <path d="M194.093 2.87543L199.297 13.3512H188.861L194.093 2.87543ZM192.221 0.304108L181.386 21.6959H184.691L187.421 16.2267H200.725L203.442 21.6959H206.886L196.051 0.304108H192.219H192.221Z" fill={color}/>
    <path d="M10.9999 2.84869H5.99994V6.84869H10.9999V2.84869Z" fill={color}/>
    <path d="M16.9999 6.84869H10.9999V18.8487H16.9999V6.84869Z" fill={color}/>
    <path d="M5.99994 6.84869H-6.10352e-05V18.8487H5.99994V6.84869Z" fill={color}/>
  </svg>
)

export const Play = ({ color = '#FFFFFF' }) => (
  <svg width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1104.000000, -604.000000)" fill={color} fillRule="nonzero">
        <g transform="translate(1104.000000, 604.000000)">
          <path d="M23,0 C10.31205,0 0,10.31205 0,23 C0,35.68795 10.31205,46 23,46 C35.68795,46 46,35.68795 46,23 C46,10.31205 35.68795,0 23,0 Z M23,2.3 C34.44595,2.3 43.7,11.55405 43.7,23 C43.7,34.44595 34.44595,43.7 23,43.7 C11.55405,43.7 2.3,34.44595 2.3,23 C2.3,11.55405 11.55405,2.3 23,2.3 Z M16.675,12.65 L16.675,33.35 L33.925,23 L16.675,12.65 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)


export const Instagram = ({ color = '#000000' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92285C14.0378 3.92285 14.3784 4.26342 14.3784 4.68319C14.3784 5.10327 14.0378 5.44385 13.6178 5.44385C13.198 5.44385 12.8574 5.10327 12.8574 4.68319C12.8574 4.26342 13.198 3.92285 13.6178 3.92285ZM6.16642 2.10742H12.0954C14.3216 2.10742 16.1424 3.91917 16.1424 6.13309V12.0917C16.1424 14.3056 14.3217 16.1171 12.0954 16.1171H6.16642C3.94016 16.1171 2.11914 14.3056 2.11914 12.0917V6.13304C2.11914 3.91917 3.94016 2.10742 6.16642 2.10742Z" fill={color}/>
  </svg>
)

export const Facebook = ({ color = '#000000' }) => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49751 17.25H1.99846V8.99896H0.25V6.15593H1.99846V4.44907C1.99846 2.12986 2.9852 0.75 5.79043 0.75H8.12527V3.59407H6.66599C5.57394 3.59407 5.50178 3.99137 5.50178 4.73285L5.49697 6.15593H8.1413L7.83181 8.99896H5.49697V17.25H5.49751Z" fill={color}/>
  </svg>
)

export const YouTube = ({ color = '#000000' }) => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.6426 10.5083V3.63453C19.6426 3.63453 19.6426 0.321411 16.3191 0.321411H3.67926C3.67926 0.321411 0.356873 0.321411 0.356873 3.63453V10.5083C0.356873 10.5083 0.356873 13.8214 3.67926 13.8214H16.3191C16.3191 13.8214 19.6426 13.8214 19.6426 10.5083ZM13.7436 7.08103L7.42998 10.7773V3.38358L13.7436 7.08103Z" fill={color}/>
  </svg>
)

export const Sprout = ({ color = '#000000' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0682 11.3015C17.9999 11.2823 16.97 11.4486 15.9465 11.7557C13.3237 12.5425 12.025 14.4233 11.5453 16.9949C11.3981 18.0888 11.3022 18.8884 11.2958 19.0547C11.2958 19.0931 11.2958 19.1571 11.3406 19.2083C11.4045 19.285 11.5197 19.285 11.5581 19.285C14.1425 19.3042 15.4795 18.6197 15.4795 18.6197C16.9252 17.8777 17.744 16.8605 17.9168 16.6494C17.9168 16.6494 19.145 15.2869 19.3625 11.5766C19.3689 11.3527 19.2665 11.3079 19.0682 11.3015Z" fill={color}/>
    <path d="M13.2526 3.79769H13.2462C13.1439 2.07687 11.7429 0.714294 10.0349 0.714294C8.32685 0.714294 6.92589 2.07687 6.82354 3.79769H6.81714V4.88519H13.2526V3.79769Z" fill={color}/>
    <path d="M8.52559 16.9949C8.03941 14.4233 6.7472 12.5425 4.1244 11.7557C3.10086 11.4486 2.07093 11.2823 1.00262 11.3015C0.797914 11.3079 0.701958 11.3463 0.714752 11.5766C0.932253 15.2869 2.16049 16.6494 2.16049 16.6494C2.32682 16.8605 3.15204 17.8777 4.59778 18.6197C4.59778 18.6197 5.93477 19.3106 8.51919 19.285C8.55758 19.285 8.67272 19.285 8.7367 19.2083C8.78148 19.1571 8.78148 19.0931 8.78148 19.0547C8.77508 18.8884 8.67272 18.0888 8.52559 16.9949Z" fill={color}/>
    <path d="M14.8203 8.69787H14.8331C14.8523 8.38442 14.8651 8.05817 14.8651 7.71912H14.8715V7.29052C14.8715 7.25213 14.8715 7.22015 14.8715 7.18176V6.77235H13.253V5.7872H12.5557H7.51484H6.81756V6.77235H5.1991V7.71912H5.20549C5.21189 8.05177 5.22469 8.37802 5.23748 8.69787H5.25027C5.38461 10.7066 6.99028 12.3442 9.10132 12.7344V19.2595H10.9885V12.7344C13.0803 12.3442 14.686 10.7066 14.8203 8.69787ZM13.9951 7.72552C13.9823 7.91743 13.9759 8.10934 13.9631 8.29486H13.9567C13.848 10.0477 12.6133 11.487 10.9757 11.89V10.9752H10.9629C12.2231 10.585 13.1635 9.42714 13.2466 8.03258H13.253V7.71912H13.9951V7.72552ZM6.10748 8.29486H6.10109C6.08829 8.10294 6.08189 7.91103 6.0691 7.72552H6.81116V8.03897H6.81756C6.90072 9.43354 7.8347 10.5914 9.10132 10.9816H9.08852V11.8964C7.45087 11.487 6.21623 10.0477 6.10748 8.29486Z" fill={color}/>
  </svg>
)

export const Search = ({ color = '#000000' }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.491 8.84128C10.1902 7.96429 10.5736 6.85386 10.5736 5.73008C10.5736 2.96155 8.31204 0.700012 5.52952 0.700012C2.74756 0.700012 0.5 2.96155 0.5 5.73008C0.5 8.49862 2.76154 10.7602 5.53007 10.7602C6.6405 10.7602 7.75035 10.3763 8.64126 9.67758L12.1637 13.2L13.0001 12.3776L9.491 8.84128ZM5.53015 9.56782C3.43295 9.56782 1.71967 7.85453 1.71967 5.75733C1.71967 3.66013 3.43295 1.94685 5.53015 1.94685C7.62735 1.94685 9.34064 3.66013 9.34064 5.75733C9.34064 7.85453 7.62735 9.56782 5.53015 9.56782Z" fill={color}/>
  </svg>
)

export const Icon = ({ color = '#FFFFFF' }) => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.0233 0H11.7383V9.35046H23.0233V0Z" fill={color}/>
    <path d="M34.7617 9.35046H23.0234V34H34.7617V9.35046Z" fill={color}/>
    <path d="M11.7383 9.35046H0V34H11.7383V9.35046Z" fill={color}/>
  </svg>
)

export const Sold = ({ color = '#FFFFFF' }) => (
  <svg width="254" height="68" viewBox="0 0 254 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M253.947 0.0530701H-1.94629V67.9469H253.947V0.0530701Z" fill="#6E6E6E"/>
    <path d="M213.632 23.7617H217.849C218.647 23.7617 220.2 23.7617 221.31 23.9825C225.349 24.7765 227.302 29.2335 227.302 33.9995C227.302 38.5446 225.482 43.1789 221.31 44.0175C220.201 44.2383 218.648 44.2383 217.849 44.2383H213.632V23.7617ZM217.847 49.8863C218.335 49.8863 220.199 49.8863 221.665 49.7101C229.387 48.7834 233.692 42.075 233.692 33.9995C233.692 25.9239 229.387 19.2166 221.665 18.2889C220.2 18.1127 218.336 18.1127 217.847 18.1127H207.506V49.8863H217.847ZM203.735 49.8863V44.2818H189.977V18.1137H183.941V49.8873H203.735V49.8863ZM163.57 44.8995C157.49 44.8114 154.472 40.6187 154.472 33.9995C154.472 27.3802 157.49 23.0113 163.57 23.0994C169.651 23.1875 172.668 27.3802 172.668 33.9995C172.668 40.6198 169.651 44.9887 163.57 44.8995ZM163.57 50.5486C173.156 50.5486 179.06 43.8848 179.06 33.9995C179.06 24.1141 173.157 17.4514 163.57 17.4514C153.984 17.4514 148.08 24.1152 148.08 33.9995C148.08 43.8848 153.984 50.5486 163.57 50.5486ZM132.148 50.5486C138.671 50.5486 144.974 47.2836 144.974 40.4425C144.974 34.0441 139.426 32.4551 136.143 31.5719L130.595 30.0721C128.554 29.5424 126.334 28.7485 126.334 26.6744C126.334 24.4241 128.997 23.0113 131.837 23.0994C134.722 23.1875 137.474 24.82 138.184 27.9099L144.707 26.807C143.154 20.8491 138.538 17.5841 131.925 17.496C125.356 17.4514 119.986 20.5848 119.986 26.9843C119.986 33.0304 124.734 34.5748 127.22 35.2806L135.519 37.7082C138.004 38.4586 138.626 39.6061 138.626 40.8851C138.626 43.5335 135.519 44.9452 132.545 44.9452C128.995 44.9452 125.932 42.9157 125.045 39.2526L118.742 40.1793C119.722 46.6212 125.047 50.5486 132.148 50.5486Z" fill={color}/>
    <path d="M40.776 17.4896H29.7529V26.5703H40.776V17.4896Z" fill={color}/>
    <path d="M52.2414 26.5714H40.7764V50.5104H52.2414V26.5714Z" fill={color}/>
    <path d="M29.7531 26.5714H18.2881V50.5104H29.7531V26.5714Z" fill={color}/>
  </svg>
)

